<template src='./Editar.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./Editar.css">
</style>

<script>
import Dialog from 'primevue/dialog';
import { BFormInvalidFeedback } from 'bootstrap-vue';
import Crud from '../../../service/Crud';
import removeNullable from "../../../tools/removeNullable";

export default {

   components:
    {
        Dialog, BFormInvalidFeedback
    },
    data()
    {
        return{
            selected:null,
            modalform:false,
            model:{},
            service:null,


        }
    },
    created:function () {
      this.$moment.locale('es');
      this.service = Crud('UserApp');
    },
    methods:{
      openModal(item)
      {
        this.selected = item;
        this.model = Object.assign({}, item);
        this.modalform = true;
      },
      onSubmit(){
          this.$refs.observer.validate();
          if(!this.$refs.observer.flags.invalid){
              this.sendModelForm(this.model.id);
          }
      },
      sendModelForm(id = null){
          let send = Object.assign({}, this.model)
          send = removeNullable(send);

          this.service.update(id,send).then( () => {
              this.reload();
              this.onSuccess();

          },  (response) => {
              this.onError(response)
          });

      },

      onSuccess(){
          this.$toast.add({severity:'success', summary:"Guardado", life: 3000});
          this.modalform = false;
          this.reload();

      },
      onError(response){
          this.$toast.add({severity:'error', summary: response.data.message, life: 3000});
      },
      reload()
      {
        this.$emit('reset');
      }
    }

}
</script>
