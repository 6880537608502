<template src='./AllowConsultation.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./AllowConsultation.css">
</style>

<script>
import Dialog from 'primevue/dialog';
import { BFormInvalidFeedback } from 'bootstrap-vue';
import Crud from '../../../service/Crud';

export default {

   components:
    {
        Dialog, BFormInvalidFeedback
    },
    created:function () {
      this.$moment.locale('es');
      this.service = Crud('UserApp');
    },
    data()
    {
        return{
            selected:null,
            modalform:false,
            model:{},
            passwordRequired:null,
            menu:[]
        }
    },
    methods:{
      openModal(item)
      {
        this.selected = item;
        this.model = Object.assign({}, item);
        this.modalform = true;
      },
      onToggleConsultation(){
          this.service.patch(this.selected.id,'toggle-consult').then(()=>{
              this.modalform = false;
              this.$toast.add({severity:'success', summary:"Guardado", life: 3000});
              this.reload();

          });
      },
      reload()
      {
        this.$emit('reset');
      }
    }

}
</script>
