<template src='./AppUsersIndex.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./AppUsersIndex.css">
</style>

<script>
import Crud from '../../service/Crud';
import EditarComponent from './Editar/Editar';
import ResetPasswordComponent from './ResetPassword/ResetPassword';
import AllowConsultationComponent from './AllowConsultation/AllowConsultation';
import DeleteComponent from './Delete/Delete';
import {MenuLoadedService} from '../../service/Utils';
//import utils,{MenuLoadedService} from '../../service/Utils';
//import {routesNames} from '../../constants/routes';

export default {
  components:
  {
    EditarComponent,
    ResetPasswordComponent,
    AllowConsultationComponent,
    DeleteComponent

  },
  data()
  {
    return {
      filters: {
            page:1,
            perPage: 10,
            totalRows:0
        },
        totalRows: 0,
        service:null,
        data:[],
        allowViewPayment:false,

        supervisors:[
                    {
                        text:'Todos'
                    },
                    {
                        id:'S',
                        text:'Supervisor de Campo'
                    },
                    {
                        id:'N',
                        text:'Trabajador de Campo'
                    }
                ],
                outs:[
                    {
                        text:'Todos'
                    },
                    {
                        id:'1',
                        text:'Inactivos'
                    },
                    {
                        id:'0',
                        text:'Activos'
                    }
                ],
    }
  },
  created: function ()
  {
    this.$moment.locale('es');
    this.service = Crud('UserApp');
    this.reload();
    this.AllowViewPayment();

    MenuLoadedService.getNotificate().subscribe(m => {
    if (m.allow)
      {
         this.AllowViewPayment();
      }

    });
  },
   methods:{
    clearFilters() {
        const omit  = ['perPage', 'totalRows', 'page'];
        Object.keys(this.filters).map(((value) => { if( omit.indexOf(value) === -1){ this.filters[value] = null} }));
        this.reload();
    },
     onPage(event){
                this.filters.page  = event.page + 1;
                this.reload();
            },
    reload()
    {
      this.service.findAll(this.filters).then (res =>{
        this.data = res.data.data;
        this.filters.totalRows = res.data.total;
        // console.log(this.data);
      })
    },

    AllowViewPayment()
    {
      this.allowViewPayment = true;
      // let temp = utils.allowMenu(routesNames.paymentTickers.path);
      // temp = !temp + temp - !temp;
      // if(utils.allowMenu(routesNames.paymentTickers.path))
      // {


      // }
    },
    changeRoute(item){
      this.$router.push({ name: 'payment-tickers', params: { userId: item.id } })
    },

    onEdit(item){
      this.$refs.Editar.openModal(item);
    },

    onShowConsulted(item){
      this.$refs.AllowConsultation.openModal(item);

    },

    onResetPassword(item){
      this.$refs.ResetPassword.openModal(item);
    },

    OnDelete(item){
      this.$refs.Delete.openModal(item);
    }
   }
}
</script>
