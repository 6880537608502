<template src='./ResetPassword.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./ResetPassword.css">
</style>

<script>
import Dialog from 'primevue/dialog';
import { BFormInvalidFeedback } from 'bootstrap-vue';
import Crud from '../../../service/Crud';

export default {

   components:
    {
        Dialog, BFormInvalidFeedback
    },

    data()
    {
        return{
            selected:null,
            modalform:false,
            model:{},
            passwordRequired:null,
            service:null


        }
    },
    created:function () {
      this.$moment.locale('es');
      this.service = Crud('UserApp');
    },
    methods:{
      openModal(item)
      {
        this.selected = item;
        this.model = Object.assign({}, item);
        this.modalform = true;
      },
      passwordChange() {

          this.service.patch(this.selected.id, 'password-reset').then(() => {
            this.modalform = false;
            this.reload();
            });
      },
      reload()
      {
        this.$emit('reset');
      }
    }

}
</script>
